import { Flex, FlexProps } from "@chakra-ui/react";
import PacmanLoader from "react-spinners/PacmanLoader";

interface SpinnerProps extends FlexProps {
  loading?: boolean;
}

export default function Spinner({ loading, ...rest }: SpinnerProps) {
  return (
    <Flex width="100%" height="100%" justify="center" align="center" {...rest}>
      <PacmanLoader
        size={50}
        margin={10}
        color="black"
        loading={loading ?? true}
      />
    </Flex>
  );
}
