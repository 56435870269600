import { initializeApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  logEvent as fbLogEvent,
  setUserId as fbSetUserId,
} from "firebase/analytics";
import { browserLocalPersistence, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBwXJMsNUbXckf-_g3qObWU2nmGBMFva4M",
  authDomain: "troy-app-prod.firebaseapp.com",
  projectId: "troy-app-prod",
  storageBucket: "troy-app-prod.appspot.com",
  messagingSenderId: "985621961592",
  appId: "1:985621961592:web:4a063b7839cfb76e26068d",
  measurementId: "G-ZWYMYMBPVT",
};

export const app = initializeApp(firebaseConfig);
export const analytics =
  process.env.NODE_ENV === "production" ? getAnalytics(app) : null;
export const auth = getAuth(app);
export const db = getFirestore(app);

export const logEvent = (
  eventName: string,
  params:
    | {
        [key: string]: any;
      }
    | undefined
) => {
  if (process.env.NODE_ENV === "production") {
    fbLogEvent(analytics as Analytics, eventName, params);
  }
};

export const setUserId = (userId: string) => {
  if (process.env.NODE_ENV === "production") {
    fbSetUserId(analytics as Analytics, userId);
  }
};

auth.setPersistence(browserLocalPersistence).catch();
