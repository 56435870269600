import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export enum Languages {
  EN = "en-gb",
  TR = "tr",
}

function getLanguage() {
  const language = localStorage?.getItem("language");
  if (language) return language as Languages;

  const lang = navigator.language?.slice(0, 2);
  if (lang.includes("en")) return Languages.EN;
  if (lang.includes("tr")) return Languages.TR;

  return Languages.EN;
}

interface LangContextType {
  lang: Languages;
  toggleLang: () => void;
}

const LangContext = createContext<LangContextType>({} as LangContextType);

export function LangProvider({ children }: { children: ReactNode }) {
  const [lang, setLang] = useState<Languages>(getLanguage());

  const toggleLang = useCallback(
    () =>
      setLang((prev) => {
        const newVal = prev === Languages.EN ? Languages.TR : Languages.EN;
        localStorage?.setItem("language", newVal);
        return newVal;
      }),
    []
  );

  const memoizedValue = useMemo(
    () => ({
      lang,
      toggleLang,
    }),
    [lang, toggleLang]
  );

  return (
    <LangContext.Provider value={memoizedValue}>
      {children}
    </LangContext.Provider>
  );
}

export default function useLang() {
  return useContext(LangContext);
}
