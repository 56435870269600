import { Languages } from "context/Lang";
import en from "./en";
import tr from "./tr";

const locales = [
  { language: Languages.EN, messages: en },
  { language: Languages.TR, messages: tr },
];

export default locales;
