const tr = {
  login: {
    login: "Giriş Yap",
    selectWallet: "Cüzdan Seç",
    with: "{wallet} ile Giriş Yap",
    installMetamask: "MetaMask'i İndir",
  },
  loading: "Yükleniyor...",
  sidebar: {
    dashboard: "Ansayfa",
    tutorials: "Rehberler",
    games: "Oyunlar",
    finances: "Finans",
    account: "Hesabım",
    logout: "Çıkış Yap",
  },
  badges: {
    upcoming: "Yakında",
  },
  tutorial: {
    lastUpdate: "Son Güncelleme",
  },
  error: {
    error: "Hata",
    genericMessage: "Bir hata oluştu. Lütfen tekrar deneyin.",
    notFound: "404 Bulunamadı",
    notFoundMessage: "Aradığınız sayfa bulunamadı.",
  },
  search: {
    search: "Arama",
    results: "Arama Sonuçları",
    noResult: "Sonuç bulunamadı",
  },
  apply: "Başvur",
  save: "Kaydet",
  account: {
    success: "Başarılı!",
    successMessage:
      "Email doğrulama iletisi gönderildi. Lütfen posta kutunuzu kontrol edin.",
    verifiedEmail: "Geçerli doğrulanmış email adresiniz: {email}",
    verify: "Lütfen email adresinizi doğrulayın",
    email: "Email Adresi",
  },
};

export default tr;
