import axios from "redaxios";

const baseURL = " https://europe-west3-troy-app-prod.cloudfunctions.net";

const instance = axios.create({ baseURL });

export function login(address: string) {
  return instance
    .post("/auth/login", { address })
    .then((res: { data: { nonce: string } }) => res.data);
}

export function verify(address: string, signature: string) {
  return instance
    .post("/auth/verify", { address, signature })
    .then((res: { data: { token: string } }) => res.data);
}
