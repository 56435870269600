import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { TranslationsProvider } from "@eo-locale/react";
import theme from "utils/theme";
import useAuth, { AuthProvider } from "context/Auth";
import locales from "locales";
import useLang from "context/Lang";
import Spinner from "components/Spinner";

const SidebarWithHeader = lazy(
  () => import("layouts/SidebarWithHeaders/SidebarWithHeader")
);
const Login = lazy(() => import("pages/Login/Login"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Search = lazy(() => import("pages/Search"));
const SingleTutorial = lazy(() => import("pages/SingleTutorial"));
const Tutorials = lazy(() => import("pages/Tutorials"));
const Account = lazy(() => import("pages/Account"));
const NotFound = lazy(() => import("pages/NotFound"));

function RequireAuth({ children }: { children: JSX.Element }) {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  const { lang } = useLang();

  return (
    <TranslationsProvider locales={locales} language={lang}>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <Routes>
            <Route
              path="/login"
              element={
                <Suspense fallback={<Spinner />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <SidebarWithHeader />
                  </RequireAuth>
                </Suspense>
              }
            >
              <Route
                path="/"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="/search/:query"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Search />
                  </Suspense>
                }
              />
              <Route
                path="/tutorials"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Tutorials />
                  </Suspense>
                }
              />
              <Route
                path="/tutorials/:tutorialUid"
                element={
                  <Suspense fallback={<Spinner />}>
                    <SingleTutorial />
                  </Suspense>
                }
              />
              <Route
                path="/account"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Account />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<Spinner />}>
                    <NotFound />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </ChakraProvider>
    </TranslationsProvider>
  );
}

export default App;
