import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "system",
  },
  colors: {
    brand: {
      darkGrey: "#1f1f1f",
      darkGreen: "#104442",
      whiteish: "#f5f5f5",
      lightGray: "#e6e6e6",
      neonPink: "#ff3c78",
      brightOrange: "#ffa924",
      lightGreen: "#006d65",
      softPink: "#f6e4d6",
      brightYellow: "#f9cb30",
      brightBlue: "#019dc5",
      purple: "#d8c3ee",
      neonBlue: "#98acff",
      darkPurple: "#c7a2cd",
      neonGreen: "#34bca4",
      darkBlue: "#2068ba",
    },
  },
  fonts: {
    body: "NeuePlak, system-ui, sans-serif",
    heading: "NeuePlak, Georgia, serif",
  },
  shadows: {
    outline: "0 0 0 3px rgba(0, 0, 0, 0.6)",
    all: "rgb(29 11 69 / 2%) 0px -1px 10px, rgb(29 11 69 / 13%) 0px 4px 10px",
  },
  components: {
    Button: {
      variants: {
        black: {
          backgroundColor: "blackAlpha.900",
          color: "white",
          _hover: {
            backgroundColor: "blackAlpha.800",
          },
          _loading: {
            _hover: {
              backgroundColor: "black",
            },
          },
        },
      },
    },
  },
});

export default theme;
