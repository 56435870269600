import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Sentry from "@sentry/react";
import * as api from "../utils/api";
import {
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
  User,
} from "firebase/auth";
import { auth, setUserId } from "../utils/initFirebase";
import Spinner from "components/Spinner";
import { Web3Provider } from "@ethersproject/providers";
import { useToast } from "@chakra-ui/react";
import { useTranslator } from "@eo-locale/react";

interface AuthContextType {
  user?: User;
  error?: any;
  login: (provider: Web3Provider) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<undefined | User>();
  const [loadingInitial, setLoadingInitial] = useState(true);
  const toast = useToast();
  const { translate } = useTranslator();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
      setLoadingInitial(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
    }
  }, [user]);

  const login = useCallback(
    async (provider: Web3Provider) => {
      try {
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        const { nonce } = await api.login(address);
        const signature = await signer.signMessage(nonce);
        const { token } = await api.verify(address, signature);
        const userCredential = await signInWithCustomToken(auth, token);

        setUser(userCredential.user);
      } catch (err) {
        // user rejected error, ignore
        if ((err as any)?.code !== 4001) {
          Sentry.captureException(err);
          toast({
            title: translate("error.error"),
            description: translate("error.genericMessage"),
            status: "error",
            duration: 9000,
            position: "top",
            isClosable: true,
          });
        }

        throw err;
      }
    },
    [toast, translate]
  );

  const logout = useCallback(() => {
    signOut(auth)
      .then(() => setUser(undefined))
      .catch(console.log);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user, login, logout]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {loadingInitial ? (
        <Spinner loading={loadingInitial} h="50vh" />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
