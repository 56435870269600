const en = {
  login: {
    login: "Login",
    selectWallet: "Select Wallet",
    with: "Login with {wallet}",
    installMetamask: "Install MetaMask",
  },
  loading: "Loading...",
  sidebar: {
    dashboard: "Dashboard",
    tutorials: "Tutorials",
    games: "Games",
    finances: "Finances",
    account: "My Account",
    logout: "Log Out",
  },
  badges: {
    upcoming: "Upcoming",
  },
  tutorial: {
    lastUpdate: "Last Update",
  },
  error: {
    error: "Error",
    genericMessage: "An error occured. Please try again.",
    notFound: "404 Not Found",
    notFoundMessage: "The page you are looking for could not be found.",
  },
  search: {
    search: "Search",
    results: "Search Results",
    noResult: "No results found",
  },
  apply: "Apply",
  save: "Save",
  account: {
    success: "Success!",
    successMessage: "Email verification sent. Please check your inbox.",
    verifiedEmail: "Your current verified email is {email}",
    verify: "Please verify your email",
    email: "Email Address",
  },
};

export default en;
