import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import { ColorModeScript } from "@chakra-ui/react";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./utils/prismic";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./utils/theme";
import { LangProvider } from "context/Lang";
import "index.css";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: "https://95e7c1e40d634ff4b0f28f10b4e42a42@o1151454.ingest.sentry.io/6228147",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <BrowserRouter>
      <PrismicProvider client={client}>
        <LangProvider>
          <App />
        </LangProvider>
      </PrismicProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
